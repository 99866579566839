import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendToEmail } from "../../store/actions/userActions";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toastService } from "../../services/toastService";
import { useMediaQuery } from "react-responsive";
import loadingGif from "../../assets/imgs/loading-gif.gif";
import logo from "../../assets/imgs/logo.png";
import logom from "../../assets/imgs/logom.png";
import styles from './PasswordReset.module.css';
import "react-toastify/dist/ReactToastify.css";

function PasswordReset() {
    const dispatch = useDispatch();
    const history = useHistory();
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const [spinner, setSpinner] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [regis, setRegis] = useState(false);

    const [cred, setCred] = useState({
        to: "",
    });

    const handleChange = async ({ target }) => {
        const field = target.name;
        let value = target.type === "number" ? +target.value || "" : target.value;
        const em = document.getElementById("to");
        if (value !== null) {
            if (!value.match(/.+@.+/)) {
                em.setCustomValidity(
                    "メールアドレスが無効です"
                );
                setIsEmailValid(false);
            } else {
                em.setCustomValidity("");
                setIsEmailValid(true);
            }
        }
        setCred((prevCred) => ({ ...prevCred, [field]: value }));
    };

    const doSubmit = async () => {
        setSpinner(true);
        setRegis(false)
        dispatch(sendToEmail(cred, 2)).then((em) => {
            if (em.status === 500) {
                setSpinner(false);
                toastService.notifyError("会員登録のないメールアドレスです");
                history.push("/forgot");
            } else if (em.status === 200) {
                setSpinner(false);
                setRegis(true)
                toastService.notifySuccess("パスワード再発行のメールが送信されました");
                // history.push("/signin");
            }
        });
    };

    useEffect(() => {
        setRegis(false)
    }, [dispatch]);

    return (
        <main className="loginPage">
            <ToastContainer />
            <section className="background">
                <div className="loginContainer">
                    {spinner && <form className="formWrapper"><div className="gif-container">
                        <img className="loading-gif" src={loadingGif} alt="ok"/>
                    </div></form>}
                    {!spinner && !regis && <form className="formWrapper" onSubmit={(ev) => { ev.preventDefault(); doSubmit(); }}>
                        <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? "serviceLogo" : "serviceLogom"} onClick={(ev) => { ev.preventDefault(); history.push("/");}}/>
                        <label className={styles.label}>ご登録<span className={styles.mini}>（とうろく）</span>のメールアドレス</label>
                        <input
                            className={styles.input}
                            onChange={handleChange}
                            type="email"
                            id="to"
                            name="to"
                            value={cred.to}
                            placeholder="メールアドレス"
                        />
                        <div className={styles.instructionsDiv}>
                            <div className={styles.instructions}>
                                <p className={styles.inst}>ご登録</p><span className={styles.mini}>（とうろく）</span><p className={styles.inst}>されているメールアドレス宛</p><span className={styles.mini}>（あて）</span><p className={styles.inst}>にパスワードを再</p>
                            </div>
                            <div className={styles.instructions}>
                                <p className={styles.inst}>送信</p><span className={styles.mini}>（さいそうしん）</span><p className={styles.inst}>いたします。</p>
                            </div>
                            <div className={styles.instructions}>
                                <p className={styles.inst}>メールアドレスをお忘</p><span className={styles.mini}>（わす）</span><p className={styles.inst}>れの場合</p><span className={styles.mini}>（ばあい）</span><p className={styles.inst}>は、お手数</p><span className={styles.mini}>（てすう）</span><p className={styles.inst}>で</p>
                            </div>
                            <div className={styles.instructions}>
                                <p className={styles.inst}>すが再度</p><span className={styles.mini}>（さいど）</span><p className={styles.inst}>アカウントの作成</p><span className={styles.mini}>（さくせい）</span><p className={styles.inst}>をおねがいいたします。</p>
                            </div>
                        </div>
                        <button type="submit" disabled={!isEmailValid} className={!isEmailValid ? styles.submitButton : styles.submitButtonActive}>送信する<br></br><span className={styles.mini}>(そうしん)</span></button>
                        <div className={styles.instructionsDiv}>
                            <div className={styles.instructions1}>
                                <p className={styles.inst}>info@jpagriculture-el.jp からメールが送信</p><span className={styles.mini}>（そうしん）</span><p className={styles.inst}>されます。</p>
                            </div>
                            <div className={styles.instructions1}>
                                <p className={styles.inst}>受信設定</p><span className={styles.mini}>（じゅしんせってい）</span><p className={styles.inst}>を承諾</p><span className={styles.mini}>（しょうだく）</span><p className={styles.inst}>してください</p>
                            </div>
                        </div>
                        {/* <p style={{ alignSelf: "center" }}><Link to="/">サインイン</Link></p> */}
                    </form>}
                    {!spinner && regis && <form className="formWrapper">
                        <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? "serviceLogo" : "serviceLogom"} onClick={(ev) => { ev.preventDefault(); history.push("/");}}/>
                        <label className="inputLabel">PW再通知（さいつうち）メールアドレス</label>
                        <p id="emailInput" className="inputLabel">{cred.to}</p>
                        <div className={styles.instructionsDiv}>
                            <div className={styles.instructions3}>
                                <p className={styles.inst}>ご登録</p><span className={styles.mini}>（とうろく）</span><p className={styles.inst}>されているメールアドレス宛</p><span className={styles.mini}>（あて）</span><p className={styles.inst}>にパスワードを</p>
                            </div>
                            <div className={styles.instructions3}>
                                <p className={styles.inst}>再通知</p><span className={styles.mini}>（さいつうち）</span><p className={styles.inst}>いたしました。</p>
                            </div>
                            <div className={styles.instructions3}>
                                <p className={styles.inst}>メールをご確認</p><span className={styles.mini}>（かくにん）</span><p className={styles.inst}>ください。</p>
                            </div>
                        </div>
                        <Link to="/signin"><button className={styles.submitButtonActive}>ログインへ戻 <span className={styles.mini}>（もど）</span> る</button></Link>
                    </form>}
                </div>
            </section>
        </main >
    );

}

export default PasswordReset;