import { Route, Redirect } from 'react-router-dom'
import { userService } from '../services/user/userService'

function PrivateRoute({ component: Component, ...rest }) {

  const userLogin = userService.getLoginUser()
  const checkExpried = userService.checkExpried(userLogin)

  if (!checkExpried) {
    // console.log("checkExpried")
    userService.renew(userLogin)
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        (userLogin && !checkExpried) ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  )
}

export default PrivateRoute
