import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { TearmModal } from "../../cmps/terms/TearmModal";
import { sendToEmail, logout } from "../../store/actions/userActions";
import { toastService } from "../../services/toastService";
import { ToastContainer } from "react-toastify";
import logo from "../../assets/imgs/logo.png";
import logom from "../../assets/imgs/logom.png";
import styles from './RegistrationForm.module.css';

const RegistrationForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isDesktop = useMediaQuery({ minWidth: 992 });

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [register] = useState(true);
    const [cred, setCred] = useState({
        to: "",
    });

    const [hidden, setHidden] = useState(false);
    const [isShowTearmModal, setIsShowTearmModal] = useState(false);
    const [check, setCheck] = useState(false);

    const toggleShowTearmModal = () => {
        // const checkbox = document.getElementById("terms");
        // if (!checkbox.checked) {
        //     checkbox.setCustomValidity("利用規約に同意する場合は、チェックボックスをオンにしてください");
        //     return;
        // } else {
        //     checkbox.setCustomValidity("");
        //     setIsShowTearmModal((prev) => !prev);
        // }
        setIsShowTearmModal((prev) => !prev);
        setCheck(true)
    };

    const handleCheck = () => {
        const checkbox = document.getElementById("terms");
        setCheck(checkbox.checked)
    };

    const handleChange = async ({ target }) => {
        const field = target.name;
        let value = target.type === "number" ? +target.value || "" : target.value;
        const em = document.getElementById("to");
        if (value !== null) {
            if (!value.match(/.+@.+/)) {
                em.setCustomValidity("メールアドレスが無効です");
                setIsEmailValid(false);
            } else {
                em.setCustomValidity("");
                setIsEmailValid(true);
            }
        }
        setCred((prevCred) => ({ ...prevCred, [field]: value }));
    };

    const doSubmit = () => {
        const checkbox = document.getElementById("terms");
        if (!checkbox.checked) {
            checkbox.setCustomValidity("利用規約に同意する場合は、チェックボックスをオンにしてください。");
            return;
        }

        if (register) {
            dispatch(sendToEmail(cred, 3)).then((em) => {
                if (em.status === 500) {
                    toastService.notifyError("このメールアドレスはすでに登録されています");
                    history.push("/forgot");
                } else if (em.status === 200) {
                    setHidden(true);
                }
            });
        } else {
            history.push("/");
        }
    };

    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);

    return (
        <main className="loginPage">
            <ToastContainer />
            <section className="background">
                <div className="loginContainer">
                    {!hidden && <form
                        className="formWrapper"
                        onSubmit={(ev) => {
                            ev.preventDefault();
                            doSubmit();
                        }}
                    >
                        <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? "serviceLogo" : "serviceLogom"} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                        <h1 className={styles.title}>受講者登録</h1>
                        <div className={styles.min}>（じゅこうしゃとうろく）</div>

                        <label className={styles.label}>メールアドレス</label>
                        <input
                            type="email"
                            id="to"
                            name="to"
                            value={cred.to}
                            className={styles.input}
                            onChange={handleChange}
                            placeholder="メールアドレス"
                            aria-label="メールアドレス"
                        />
                        <div className={styles.regisContainer}>
                            <div className={styles.regisWrapper}>
                                <input checked={check} onChange={handleCheck} type="checkbox" id="terms" className={styles.checkbox} />
                                <div className={styles.regisTextDiv}>
                                    <div className={styles.regisText}>
                                        <p onClick={toggleShowTearmModal} style={{ textDecoration: "underline" }} className={styles.inst}>利用規約</p><p style={{ textDecoration: "underline" }} className={styles.mini}>（りようきやく）</p><p className={styles.inst}>をご参照</p><p className={styles.mini}>（さんしょう）</p><p className={styles.inst}>のうえ、</p>
                                    </div>
                                    <div className={styles.regisText}>
                                        <p className={styles.inst}>ご登録</p><p className={styles.mini}>（とうろく）</p><p className={styles.inst}>ください</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" disabled={!isEmailValid || !check} className={!isEmailValid || !check ? styles.submitButton : styles.submitButtonActive}>新規登録<br></br><p className={styles.mini}>（しんきとうろく）</p></button>
                        <div className={styles.instructionsDiv}>
                            <div className={styles.instructions1}>
                                <p className={styles.inst}>info@jpagriculture-el.jp からメールが送信</p><p className={styles.mini}>（そうしん）</p><p className={styles.inst}>されます。</p>
                            </div>
                            <div className={styles.instructions1}>
                                <p className={styles.inst}> 受信設定</p><p className={styles.mini}>（じゅしんせってい）</p><p className={styles.inst}>を承諾</p><p className={styles.mini}>（しょうだく）</p><p className={styles.inst}>してください</p>
                            </div>
                        </div>
                    </form>}
                    {hidden && <form className="formWrapper">
                        <img src={isDesktop ? logo : logom} alt="serviceLogo" className={isDesktop ? "serviceLogo" : "serviceLogom"} onClick={(ev) => { ev.preventDefault(); history.push("/"); }} />
                        <h1 className={styles.title}>受講者登録</h1>
                        <div className={styles.min}>（じゅこうしゃとうろく）</div>

                        <p className={styles.emailAddress}>{cred.to}</p>
                        <div className={styles.instructions2}>
                            <div>にメールを送信 <span className={styles.mini}>（そうしん）</span> しました。</div>
                            <br />
                            <br />
                            <p>メールに記載 <span className={styles.mini}>（きさい）</span> されたURLから、</p>
                            <p>アカウント登録 <span className={styles.mini}>（とうろく）</span> をしてください。</p>

                        </div>

                        <div className={styles.message}>
                            URLは24時間有効<span className={styles.mini}>（じかんゆうこう）</span>です
                            <br />
                            info@jpagriculture-el.jp からの受信設定<span className={styles.mini}>（じゅしんせってい）</span>を許諾<span className={styles.mini}>（しょうだく）</span>してください
                        </div>
                    </form>}
                </div>
            </section>
            {
                !hidden && isShowTearmModal && (
                    <TearmModal toggleShowTearmModal={toggleShowTearmModal} />
                )
            }
        </main >
    );

};

export default RegistrationForm;