import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updLoged, logout, getLoginUser } from '../../store/actions/userActions'

export function ExpriedModal({ toggleExpriedModal }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const doLogout = async () => {
    dispatch(getLoginUser()).then((user) => {
      if (user !== null && user.status === 200) {
        dispatch(updLoged(
          user.data.id, { "is_login": 0, "logged_at": null }
        )).then((res) => {
          if (res !== null && res.status === 200) {
            dispatch(logout()).then((res) => {
              if (res !== null) {
                toggleExpriedModal();
                history.push(`/signin`);
              }
            });
          }
        });
      }
    });
  };

  return (
    <section className="tearms">
      <div className="bg" onClick={toggleExpriedModal}></div>
      <div className="container-paving">
        <div className="form-paving">
          <div className="div-7">
            <div className="div-8">{process.env.REACT_APP_EXPRIED} 分が経過しました。再度ログインしてください。</div>
            <div className="div-91"><p onClick={doLogout}>閉じる</p></div>
          </div>
        </div>
      </div>
    </section>
  );
}
